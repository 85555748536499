nav:not(.paginate, .breadcrumb-wrapper){
  min-height:75px;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.15);
  background: linear-gradient(#2c4770 20%, #051a38 100%);
  background: -webkit-linear-gradient(#2c4770 20%, #051a38 100%);
  background: -moz-linear-gradient(#2c4770 20%, #051a38 100%);
  background:#2c4770;
}


.nav-dropdown{
  display:none;
}

.dropdown{
  display: none;
}

.dropdown:hover{
  display: block;
}

.nav-item{
  display: flex;
  align-items: center;
  font-size: 19px;;
}

.navbar-nav .nav-link{
  border-bottom: 2px solid transparent;
  padding: .5rem 1rem .2rem;
}

.navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .75rem;
    padding-left: .75rem;
}

/*.dropdown:hover>.dropdown-menu {
  display: block;
  transition: 1.5s;
}*/

.move-nav-menu{
  /*position: relative;*/
  border-radius: 0px;
  top:65px !important;
}

.dropdown-item:hover{
  background-color: #051a38;
  /* background-color: #ebebeb;*/
  color:#fff;
  text-decoration: none;
  transition: .75s;
}

.navbar-nav .nav-item::after{
  content:'';
  position: absolute;
}

.dropdown-item.disabled{
  color:#000;
  background-color: #eaeaea;
  font-weight: 700;
}

.navbar-nav .nav-item:hover:before{
  width: 100%;
}

.navbar-nav .nav-link:hover{
  border-bottom: 2px solid #fff;
  content:'';
  transition: .5s;
  background-color: rgba(255, 255, 255, 0.1);
}

.breadcrumb{
  background-color:#fff;
}

.dropdown-menu{
  padding-top:0;
  left:0;
  transition: .5s;
  opacity:.95;
  top:35px;
  
}

.dropdown-menu.show {
  opacity: .95;
  
  transition: opacity 1.0s ease-in;
}

.services h4{
  font-size:1.5em;
  font-weight: 700;
}

.services .dropdown-item{
  margin:1.25rem 1.25rem 1.25rem 0;
  padding-left:0;
}

.services .dropdown-item:hover{
  background-color: #ebebeb;
  color:#333;
  box-shadow: -1px 5px 10px 1px rgba(133,132,132,0.74);
  -webkit-box-shadow: -1px 5px 10px 1px rgba(133,132,132,0.74);
  -moz-box-shadow: -1px 5px 10px 1px rgba(133,132,132,0.74);
}

.services .menu-item div span{
  font-size:1.25em;
  font-weight:600;
}

.services .menu-item div p{
  line-break: normal;
  white-space: normal;
}


/* Progress bar */
/*#progress-bar {
  --scrollAmount: 0%;

  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  width: var(--scrollAmount);

  height: 25px;
  position: fixed;
  top: 0;
  z-index:1000;
}*/


@media (max-width: 1090px) {
  .navbar-collapse {
    position: absolute;
    /* top: 60px; */
    top:95px;
    left: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    width: 100%;
    transition: all 0.4s ease;
    display: block;
  }
  .navbar-collapse.collapsing {
    height: auto !important;
    margin-left: 50%;
    left: 50%;
    transition: all 0.2s ease;
  }
  .navbar-collapse.show {
    left: 0;
  }
}
